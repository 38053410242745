import React from "react";

function Datenschutz({ onClose }) {
  return (
    <div className="flex-center flex-col">
      <button className="text-2xl font-semibold " onClick={onClose}>
        Schließen
      </button>
      <h1>
        <a href="/">ZURÜCK ZUR StarteSeite </a>
      </h1>

      <h1>Datenschutz­erklärung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h3>Datenerfassung auf dieser Website</h3>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
        zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
      </p>
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie diese Website betreten.
      </p>
      <h4>Wofür nutzen wir Ihre Daten?</h4>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit an uns wenden.
      </p>
      <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>
      <p>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit sogenannten
        Analyseprogrammen.
      </p>
      <p>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
        folgenden Datenschutzerklärung.
      </p>
      <h2>2. Hosting</h2>
      <h3>Hetzner</h3>
      <p>
        Wir hosten unsere Website bei Hetzner. Anbieter ist die Hetzner Online
        GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).
      </p>
      <p>
        Details entnehmen Sie der Datenschutzerklärung von Hetzner:{" "}
        <a
          href="https://www.hetzner.com/de/rechtliches/datenschutz"
          target="_blank"
          rel="noopener noreferrer">
          https://www.hetzner.com/de/rechtliches/datenschutz
        </a>
        .
      </p>
      <p>
        Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
        f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst
        zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
        auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
        Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <h4>Auftragsverarbeitung</h4>
      <p>
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
        genannten Anbieter geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>I/s?m/ail Er/do/g?an Neu?kir/chen?erstr. 54 47?829 Kref?eld</p>
      <p>
        {" "}
        Bitte entschuldigen Sie die Sonderzeichen in meinem Namen. Aber leider
        wird im Internet einfach mit meinem Angaben Verträge abgeschlossen.
        Durch die Sonderzeichen will das maschinelle Lesen verhindern
      </p>

      <p>
        Telefon: 01/51??6744?05?71
        <br /> E-Mail: erdogan.isma @ gmail.com
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>

      <h3>Speicherdauer</h3>
      <p>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
      </p>
      <h3>
        Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
        dieser Website
      </h3>
      <p>
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir
        Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
        nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
        ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten
        in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von
        Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
        oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via
        Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
        zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist
        jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
        Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir
        Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren
        verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen
        Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c
        DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres
        berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über
        die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den
        folgenden Absätzen dieser Datenschutzerklärung informiert.
      </p>
      <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>
      <p>
        Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
        oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
        diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
        Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
        hin, dass in diesen Ländern kein mit der EU vergleichbares
        Datenschutzniveau garantiert werden kann. Beispielsweise sind
        US-Unternehmen dazu verpflichtet, personenbezogene Daten an
        Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
        hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
        ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf
        US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
        auswerten und dauerhaft speichern. Wir haben auf diese
        Verarbeitungstätigkeiten keinen Einfluss.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </p>
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </p>
      <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <h3>Recht auf Daten­übertrag­barkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Auskunft, Löschung und Berichtigung</h3>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit an uns wenden.
      </p>
      <h3>Recht auf Einschränkung der Verarbeitung</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <h2>4. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>
      <p>
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
      </p>
      <p>
        Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
        gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
      </p>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
        würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
        Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
        anzuzeigen.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs,
        zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B.
        für die Warenkorbfunktion) oder zur Optimierung der Website (z. B.
        Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
        Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
        gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
        notwendigen Cookies zur technisch fehlerfreien und optimierten
        Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung
        von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt
        wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
        gesondert informieren und ggf. eine Einwilligung abfragen.
      </p>
      <h3>Einwilligung mit Usercentrics</h3>
      <p>
        Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre
        Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder
        zum Einsatz bestimmter Technologien einzuholen und diese
        datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist die
        Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Website:{" "}
        <a
          href="https://usercentrics.com/de/"
          target="_blank"
          rel="noopener noreferrer">
          https://usercentrics.com/de/
        </a>{" "}
        (im Folgenden „Usercentrics“).
      </p>
      <p>
        Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten
        an Usercentrics übertragen:
      </p>
      <ul>
        <li>Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)</li>
        <li>Ihre IP-Adresse</li>
        <li>Informationen über Ihren Browser</li>
        <li>Informationen über Ihr Endgerät</li>
        <li>Zeitpunkt Ihres Besuchs auf der Website</li>
      </ul>
      <p>
        Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um
        Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu
        können. Die so erfassten Daten werden gespeichert, bis Sie uns zur
        Löschung auffordern, das Usercentrics-Cookie selbst löschen oder der
        Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche
        Aufbewahrungspflichten bleiben unberührt.
      </p>
      <p>
        Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen
        Einwilligungen für den Einsatz bestimmter Technologien einzuholen.
        Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
      </p>
      <h4>Auftragsverarbeitung</h4>
      <p>
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
        genannten Anbieter geschlossen. Hierbei handelt es sich um einen
        datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
        dieser die personenbezogenen Daten unserer Websitebesucher nur nach
        unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde;
        die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
      </p>
      <h3>Kommentar­funktion auf dieser Website</h3>
      <p>
        Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar
        auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre
        E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte
        Nutzername gespeichert.
      </p>
      <h4>Speicherung der IP-Adresse</h4>
      <p>
        Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die
        Kommentare verfassen. Da wir Kommentare auf dieser Website nicht vor der
        Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
        Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser
        vorgehen zu können.
      </p>
      <h4>Abonnieren von Kommentaren</h4>
      <p>
        Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare
        abonnieren. Sie erhalten eine Bestätigungsemail, um zu prüfen, ob Sie
        der Inhaber der angegebenen E-Mail-Adresse sind. Sie können diese
        Funktion jederzeit über einen Link in den Info-Mails abbestellen. Die im
        Rahmen des Abonnierens von Kommentaren eingegebenen Daten werden in
        diesem Fall gelöscht; wenn Sie diese Daten für andere Zwecke und an
        anderer Stelle (z. B. Newsletterbestellung) an uns übermittelt haben,
        verbleiben diese Daten jedoch bei uns.
      </p>
      <h4>Speicherdauer der Kommentare</h4>
      <p>
        Die Kommentare und die damit verbundenen Daten werden gespeichert und
        verbleiben auf dieser Website, bis der kommentierte Inhalt vollständig
        gelöscht wurde oder die Kommentare aus rechtlichen Gründen gelöscht
        werden müssen (z. B. beleidigende Kommentare).
      </p>
      <h4>Rechtsgrundlage</h4>
      <p>
        Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung
        (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte
        Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung
        per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </p>
      <h2>5. Newsletter</h2>
      <h3>Newsletter­daten</h3>
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
        benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche
        uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
        E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden
        sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis
        erhoben. Diese Daten verwenden wir ausschließlich für den Versand der
        angeforderten Informationen und geben diese nicht an Dritte weiter.
      </p>
      <p>
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
        erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
        E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können
        Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter.
        Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
        bleibt vom Widerruf unberührt.
      </p>
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
        Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns
        bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung
        des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste
        gelöscht. Wir behalten uns vor, E-Mail-Adressen aus unserem
        Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten
        Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
      </p>
      <p>
        Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben
        hiervon unberührt.
      </p>
      <p>
        Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
        E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
        Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings
        erforderlich ist. Die Daten aus der Blacklist werden nur für diesen
        Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient
        sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der
        gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
        Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in
        der Blacklist ist zeitlich nicht befristet.{" "}
        <strong>
          Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
          berechtigtes Interesse überwiegen.
        </strong>
      </p>
      <h2>6. Plugins und Tools</h2>
      <h3>YouTube</h3>
      <p>
        Diese Website bindet Videos der Website YouTube ein. Betreiber der
        Website ist die Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Irland.
      </p>
      <p>
        Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube eingebunden
        ist, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei
        wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
        haben.
      </p>
      <p>
        Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endgerät
        speichern oder vergleichbare Technologien zur Wiedererkennung verwenden
        (z. B. Device-Fingerprinting). Auf diese Weise kann YouTube
        Informationen über Besucher dieser Website erhalten. Diese Informationen
        werden u. a. verwendet, um Videostatistiken zu erfassen, die
        Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
      </p>
      <p>
        Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
        YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen.
        Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account
        ausloggen.
      </p>
      <p>
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
        entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
        Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
        den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
        Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist
        jederzeit widerrufbar.
      </p>
      <p>
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von YouTube unter:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer">
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <h3>Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung
        von Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
        einheitlichen Darstellung des Schriftbildes auf seiner Website. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
        und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
        Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers
        (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
        von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer">
          https://developers.google.com/fonts/faq
        </a>{" "}
        und in der Datenschutzerklärung von Google:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer">
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <h3>Font Awesome</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten und
        Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6 Porter Road
        Apartment 3R, Cambridge, Massachusetts, USA.
      </p>
      <p>
        Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in ihren
        Browsercache, um Texte, Schriftarten und Symbole korrekt anzuzeigen. Zu
        diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Font Awesome aufnehmen. Hierdurch erlangt Font Awesome
        Kenntnis darüber, dass über Ihre IP-Adresse diese Website aufgerufen
        wurde. Die Nutzung von Font Awesome erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der
        einheitlichen Darstellung des Schriftbildes auf unserer Website. Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
        und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
        Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers
        (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine
        Standardschrift von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Font Awesome finden Sie und in der
        Datenschutzerklärung von Font Awesome unter:{" "}
        <a
          href="https://fontawesome.com/privacy"
          target="_blank"
          rel="noopener noreferrer">
          https://fontawesome.com/privacy
        </a>
        .
      </p>

      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>
      <p>
        The following information will provide you with an easy to navigate
        overview of what will happen with your personal data when you visit this
        website. The term “personal data” comprises all data that can be used to
        personally identify you. For detailed information about the subject
        matter of data protection, please consult our Data Protection
        Declaration, which we have included beneath this copy.
      </p>
      <h3>Data recording on this website</h3>
      <h4>
        Who is the responsible party for the recording of data on this website
        (i.e., the “controller”)?
      </h4>
      <p>
        The data on this website is processed by the operator of the website,
        whose contact information is available under section “Information about
        the responsible party (referred to as the “controller” in the GDPR)” in
        this Privacy Policy.
      </p>
      <h4>How do we record your data?</h4>
      <p>
        We collect your data as a result of your sharing of your data with us.
        This may, for instance be information you enter into our contact form.
      </p>
      <p>
        Other data shall be recorded by our IT systems automatically or after
        you consent to its recording during your website visit. This data
        comprises primarily technical information (e.g., web browser, operating
        system, or time the site was accessed). This information is recorded
        automatically when you access this website.
      </p>
      <h4>What are the purposes we use your data for?</h4>
      <p>
        A portion of the information is generated to guarantee the error free
        provision of the website. Other data may be used to analyze your user
        patterns.
      </p>
      <h4>What rights do you have as far as your information is concerned?</h4>
      <p>
        You have the right to receive information about the source, recipients,
        and purposes of your archived personal data at any time without having
        to pay a fee for such disclosures. You also have the right to demand
        that your data are rectified or eradicated. If you have consented to
        data processing, you have the option to revoke this consent at any time,
        which shall affect all future data processing. Moreover, you have the
        right to demand that the processing of your data be restricted under
        certain circumstances. Furthermore, you have the right to log a
        complaint with the competent supervising agency.
      </p>
      <p>
        Please do not hesitate to contact us at any time if you have questions
        about this or any other data protection related issues.
      </p>
      <h3>Analysis tools and tools provided by third parties</h3>
      <p>
        There is a possibility that your browsing patterns will be statistically
        analyzed when your visit this website. Such analyses are performed
        primarily with what we refer to as analysis programs.
      </p>
      <p>
        For detailed information about these analysis programs please consult
        our Data Protection Declaration below.
      </p>
      <h2>2. Hosting</h2>
      <h3>Hetzner</h3>
      <p>
        We host our website with Hetzner. The provider is the Hetzner Online
        GmbH, Industriestr. 25, 91710 Gunzenhausen, Germany (hereinafter
        referred to as Hetzner).
      </p>
      <p>
        For details, please view the data privacy policy of Hetzner:{" "}
        <a
          href="https://www.hetzner.com/de/rechtliches/datenschutz"
          target="_blank"
          rel="noopener noreferrer">
          https://www.hetzner.com/de/rechtliches/datenschutz
        </a>
        .
      </p>
      <p>
        We use Hetzner on the basis of Art. 6(1)(f) GDPR. We have a legitimate
        interest in the most reliable depiction of our website possible. If
        appropriate consent has been obtained, the processing is carried out
        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
        insofar the consent includes the storage of cookies or the access to
        information in the user’s end device (e.g., device fingerprinting)
        within the meaning of the TTDSG. This consent can be revoked at any
        time.
      </p>
      <h4>Data processing</h4>
      <p>
        We have concluded a data processing agreement (DPA) with the
        above-mentioned provider. This is a contract mandated by data privacy
        laws that guarantees that they process personal data of our website
        visitors only based on our instructions and in compliance with the GDPR.
      </p>
      <h2>3. General information and mandatory information</h2>
      <h3>Data protection</h3>
      <p>
        The operators of this website and its pages take the protection of your
        personal data very seriously. Hence, we handle your personal data as
        confidential information and in compliance with the statutory data
        protection regulations and this Data Protection Declaration.
      </p>
      <p>
        Whenever you use this website, a variety of personal information will be
        collected. Personal data comprises data that can be used to personally
        identify you. This Data Protection Declaration explains which data we
        collect as well as the purposes we use this data for. It also explains
        how, and for which purpose the information is collected.
      </p>
      <p>
        We herewith advise you that the transmission of data via the Internet
        (i.e., through e-mail communications) may be prone to security gaps. It
        is not possible to completely protect data against third-party access.
      </p>
      <h3>
        Information about the responsible party (referred to as the “controller”
        in the GDPR)
      </h3>
      <p>The data processing controller on this website is:</p>
      <p>Is?mail Erdo//gan Neukirch/ eners? tr. 54 478? 29 Kref? eld</p>
      <p>
        {" "}
        Sry, for the Slashes and Question mark. But someone is grabing my Data
        on the Inet. I try to impede it with the Questionsmarks
      </p>

      <p>
        Phone: 01??5167440??571
        <br /> E-mail: erdogan.isma @ gmail.com
      </p>
      <p>
        {" "}
        Sry, for the Slashes and Question mark. But someone is grabing my Data
        on the Inet. I try to impede it with the Questionsmarks
      </p>
      <p>
        The controller is the natural person or legal entity that
        single-handedly or jointly with others makes decisions as to the
        purposes of and resources for the processing of personal data (e.g.,
        names, e-mail addresses, etc.).
      </p>

      <h3>Storage duration</h3>
      <p>
        Unless a more specific storage period has been specified in this privacy
        policy, your personal data will remain with us until the purpose for
        which it was collected no longer applies. If you assert a justified
        request for deletion or revoke your consent to data processing, your
        data will be deleted, unless we have other legally permissible reasons
        for storing your personal data (e.g., tax or commercial law retention
        periods); in the latter case, the deletion will take place after these
        reasons cease to apply.
      </p>
      <h3>
        General information on the legal basis for the data processing on this
        website
      </h3>
      <p>
        If you have consented to data processing, we process your personal data
        on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a) GDPR, if special
        categories of data are processed according to Art. 9 (1) DSGVO. In the
        case of explicit consent to the transfer of personal data to third
        countries, the data processing is also based on Art. 49 (1)(a) GDPR. If
        you have consented to the storage of cookies or to the access to
        information in your end device (e.g., via device fingerprinting), the
        data processing is additionally based on § 25 (1) TTDSG. The consent can
        be revoked at any time. If your data is required for the fulfillment of
        a contract or for the implementation of pre-contractual measures, we
        process your data on the basis of Art. 6(1)(b) GDPR. Furthermore, if
        your data is required for the fulfillment of a legal obligation, we
        process it on the basis of Art. 6(1)(c) GDPR. Furthermore, the data
        processing may be carried out on the basis of our legitimate interest
        according to Art. 6(1)(f) GDPR. Information on the relevant legal basis
        in each individual case is provided in the following paragraphs of this
        privacy policy.
      </p>
      <h3>
        Information on data transfer to the USA and other non-EU countries
      </h3>
      <p>
        Among other things, we use tools of companies domiciled in the United
        States or other from a data protection perspective non-secure non-EU
        countries. If these tools are active, your personal data may potentially
        be transferred to these non-EU countries and may be processed there. We
        must point out that in these countries, a data protection level that is
        comparable to that in the EU cannot be guaranteed. For instance, U.S.
        enterprises are under a mandate to release personal data to the security
        agencies and you as the data subject do not have any litigation options
        to defend yourself in court. Hence, it cannot be ruled out that U.S.
        agencies (e.g., the Secret Service) may process, analyze, and
        permanently archive your personal data for surveillance purposes. We
        have no control over these processing activities.
      </p>
      <h3>Revocation of your consent to the processing of data</h3>
      <p>
        A wide range of data processing transactions are possible only subject
        to your express consent. You can also revoke at any time any consent you
        have already given us. This shall be without prejudice to the lawfulness
        of any data collection that occurred prior to your revocation.
      </p>
      <h3>
        Right to object to the collection of data in special cases; right to
        object to direct advertising (Art. 21 GDPR)
      </h3>
      <p>
        IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F)
        GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR
        PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS
        ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE
        THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE
        CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE
        WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A
        POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
        PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND
        FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING
        OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1)
        GDPR).
      </p>
      <p>
        IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT
        ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR
        AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME.
        THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH
        SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL
        SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES
        (OBJECTION PURSUANT TO ART. 21(2) GDPR).
      </p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>
        In the event of violations of the GDPR, data subjects are entitled to
        log a complaint with a supervisory agency, in particular in the member
        state where they usually maintain their domicile, place of work or at
        the place where the alleged violation occurred. The right to log a
        complaint is in effect regardless of any other administrative or court
        proceedings available as legal recourses.
      </p>
      <h3>Right to data portability</h3>
      <p>
        You have the right to demand that we hand over any data we automatically
        process on the basis of your consent or in order to fulfil a contract be
        handed over to you or a third party in a commonly used, machine readable
        format. If you should demand the direct transfer of the data to another
        controller, this will be done only if it is technically feasible.
      </p>
      <h3>SSL and/or TLS encryption</h3>
      <p>
        For security reasons and to protect the transmission of confidential
        content, such as purchase orders or inquiries you submit to us as the
        website operator, this website uses either an SSL or a TLS encryption
        program. You can recognize an encrypted connection by checking whether
        the address line of the browser switches from “http://” to “https://”
        and also by the appearance of the lock icon in the browser line.
      </p>
      <p>
        If the SSL or TLS encryption is activated, data you transmit to us
        cannot be read by third parties.
      </p>
      <h3>Information about, rectification and eradication of data</h3>
      <p>
        Within the scope of the applicable statutory provisions, you have the
        right to at any time demand information about your archived personal
        data, their source and recipients as well as the purpose of the
        processing of your data. You may also have a right to have your data
        rectified or eradicated. If you have questions about this subject matter
        or any other questions about personal data, please do not hesitate to
        contact us at any time.
      </p>
      <h3>Right to demand processing restrictions</h3>
      <p>
        You have the right to demand the imposition of restrictions as far as
        the processing of your personal data is concerned. To do so, you may
        contact us at any time. The right to demand restriction of processing
        applies in the following cases:
      </p>
      <ul>
        <li>
          In the event that you should dispute the correctness of your data
          archived by us, we will usually need some time to verify this claim.
          During the time that this investigation is ongoing, you have the right
          to demand that we restrict the processing of your personal data.
        </li>
        <li>
          If the processing of your personal data was/is conducted in an
          unlawful manner, you have the option to demand the restriction of the
          processing of your data in lieu of demanding the eradication of this
          data.
        </li>
        <li>
          If we do not need your personal data any longer and you need it to
          exercise, defend or claim legal entitlements, you have the right to
          demand the restriction of the processing of your personal data instead
          of its eradication.
        </li>
        <li>
          If you have raised an objection pursuant to Art. 21(1) GDPR, your
          rights and our rights will have to be weighed against each other. As
          long as it has not been determined whose interests prevail, you have
          the right to demand a restriction of the processing of your personal
          data.
        </li>
      </ul>
      <p>
        If you have restricted the processing of your personal data, these data
        – with the exception of their archiving – may be processed only subject
        to your consent or to claim, exercise or defend legal entitlements or to
        protect the rights of other natural persons or legal entities or for
        important public interest reasons cited by the European Union or a
        member state of the EU.
      </p>
      <h2>4. Recording of data on this website</h2>
      <h3>Cookies</h3>
      <p>
        Our websites and pages use what the industry refers to as “cookies.”
        Cookies are small text files that do not cause any damage to your
        device. They are either stored temporarily for the duration of a session
        (session cookies) or they are permanently archived on your device
        (permanent cookies). Session cookies are automatically deleted once you
        terminate your visit. Permanent cookies remain archived on your device
        until you actively delete them, or they are automatically eradicated by
        your web browser.
      </p>
      <p>
        In some cases, it is possible that third-party cookies are stored on
        your device once you enter our site (third-party cookies). These cookies
        enable you or us to take advantage of certain services offered by the
        third party (e.g., cookies for the processing of payment services).
      </p>
      <p>
        Cookies have a variety of functions. Many cookies are technically
        essential since certain website functions would not work in the absence
        of the cookies (e.g., the shopping cart function or the display of
        videos). The purpose of other cookies may be the analysis of user
        patterns or the display of promotional messages.
      </p>
      <p>
        Cookies, which are required for the performance of electronic
        communication transactions, or for the provision of certain functions
        you want to use (e.g., for the shopping cart function) or those that are
        necessary for the optimization (required cookies) of the website (e.g.,
        cookies that provide measurable insights into the web audience), shall
        be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal
        basis is cited. The operator of the website has a legitimate interest in
        the storage of required cookies to ensure the technically error free and
        optimized provision of the operator’s services. If your consent to the
        storage of the cookies and similar recognition technologies has been
        requested, processing occurs exclusively on the basis of the consent
        obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be
        revoked at any time.
      </p>
      <p>
        You have the option to set up your browser in such a manner that you
        will be notified any time cookies are placed and to permit the
        acceptance of cookies only in specific cases. You may also exclude the
        acceptance of cookies in certain cases or in general or activate the
        delete function for the automatic eradication of cookies when the
        browser closes. If cookies are deactivated, the functions of this
        website may be limited.
      </p>
      <p>
        In the event that third-party cookies are used or if cookies are used
        for analytical purposes, we will separately notify you in conjunction
        with this Data Protection Policy and, if applicable, ask for your
        consent.
      </p>
      <h3>Consent with Usercentrics</h3>
      <p>
        This website uses the consent technology of Usercentrics to obtain your
        consent to the storage of certain cookies on your device or for the use
        of specific technologies, and to document the former in a data
        protection compliant manner. The party offering this technology is
        Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Germany, website:{" "}
        <a
          href="https://usercentrics.com/de/"
          target="_blank"
          rel="noopener noreferrer">
          https://usercentrics.com/
        </a>{" "}
        (hereinafter referred to as “Usercentrics”).
      </p>
      <p>
        Whenever you visit our website, the following personal data will be
        transferred to Usercentrics:
      </p>
      <ul>
        <li>
          Your declaration(s) of consent or your revocation of your
          declaration(s) of consent
        </li>
        <li>Your IP address</li>
        <li>Information about your browser</li>
        <li>Information about your device</li>
        <li>The date and time you visited our website</li>
      </ul>
      <p>
        Moreover, Usercentrics shall store a cookie in your browser to be able
        to allocate your declaration(s) of consent or any revocations of the
        former. The data that are recorded in this manner shall be stored until
        you ask us to eradicate them, delete the Usercentrics cookie or until
        the purpose for archiving the data no longer exists. This shall be
        without prejudice to any mandatory legal retention periods.
      </p>
      <p>
        Usercentrics uses cookies to obtain the declarations of consent mandated
        by law. The legal basis for the use of specific technologies is Art.
        6(1)(c) GDPR.
      </p>
      <h4>Data processing</h4>
      <p>
        We have concluded a data processing agreement (DPA) with the
        above-mentioned provider. This is a contract mandated by data privacy
        laws that guarantees that they process personal data of our website
        visitors only based on our instructions and in compliance with the GDPR.
      </p>
      <h3>Contact form</h3>
      <p>
        If you submit inquiries to us via our contact form, the information
        provided in the contact form as well as any contact information provided
        therein will be stored by us in order to handle your inquiry and in the
        event that we have further questions. We will not share this information
        without your consent.
      </p>
      <p>
        The processing of these data is based on Art. 6(1)(b) GDPR, if your
        request is related to the execution of a contract or if it is necessary
        to carry out pre-contractual measures. In all other cases the processing
        is based on our legitimate interest in the effective processing of the
        requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art.
        6(1)(a) GDPR) if this has been requested; the consent can be revoked at
        any time.
      </p>
      <p>
        The information you have entered into the contact form shall remain with
        us until you ask us to eradicate the data, revoke your consent to the
        archiving of data or if the purpose for which the information is being
        archived no longer exists (e.g., after we have concluded our response to
        your inquiry). This shall be without prejudice to any mandatory legal
        provisions, in particular retention periods.
      </p>
      <h3>Request by e-mail, telephone, or fax</h3>
      <p>
        If you contact us by e-mail, telephone or fax, your request, including
        all resulting personal data (name, request) will be stored and processed
        by us for the purpose of processing your request. We do not pass these
        data on without your consent.
      </p>
      <p>
        These data are processed on the basis of Art. 6(1)(b) GDPR if your
        inquiry is related to the fulfillment of a contract or is required for
        the performance of pre-contractual measures. In all other cases, the
        data are processed on the basis of our legitimate interest in the
        effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or
        on the basis of your consent (Art. 6(1)(a) GDPR) if it has been
        obtained; the consent can be revoked at any time.
      </p>
      <p>
        The data sent by you to us via contact requests remain with us until you
        request us to delete, revoke your consent to the storage or the purpose
        for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions - in particular statutory retention
        periods - remain unaffected.
      </p>
      <h3>The comment function on this website</h3>
      <p>
        When you use the comment function on this website, information on the
        time the comment was generated and your e-mail-address and, if you are
        not posting anonymously, the username you have selected will be archived
        in addition to your comments.
      </p>
      <h4>Storage of the IP address</h4>
      <p>
        Our comment function stores the IP addresses of all users who enter
        comments. Given that we do not review the comments prior to publishing
        them, we need this information in order to take action against the
        author in the event of rights violations, such as defamation or
        propaganda.
      </p>
      <h4>Subscribing to comments</h4>
      <p>
        As a user of this website, you have the option to subscribe to comments
        after you have registered. You will receive a confirmation e-mail, the
        purpose of which is to verify whether you are the actual holder of the
        provided e-mail address. You can deactivate this function at any time by
        following a respective link in the information e-mails. The data entered
        in conjunction with subscriptions to comments will be deleted in this
        case. However, if you have communicated this information to us for other
        purposes and from a different location (e.g., when subscribing to the
        newsletter), the data shall remain in our possession.
      </p>
      <h4>Storage period for comments</h4>
      <p>
        Comments and any affiliated information shall be stored by us and remain
        on this website until the content the comment pertained to has been
        deleted in its entirety or if the comments had to be deleted for legal
        reasons (e.g., insulting comments).
      </p>
      <h4>Legal basis</h4>
      <p>
        Comments are stored on the basis of your consent (Art. 6(1)(a) GDPR).
        You have the right to revoke at any time any consent you have already
        given us. To do so, all you are required to do is sent us an informal
        notification via e-mail. This shall be without prejudice to the
        lawfulness of any data collection that occurred prior to your
        revocation.
      </p>
      <h2>5. Newsletter</h2>
      <h3>Newsletter data</h3>
      <p>
        If you would like to subscribe to the newsletter offered on this
        website, we will need from you an e-mail address as well as information
        that allow us to verify that you are the owner of the e-mail address
        provided and consent to the receipt of the newsletter. No further data
        shall be collected or shall be collected only on a voluntary basis. We
        shall use such data only for the sending of the requested information
        and shall not share such data with any third parties.
      </p>
      <p>
        The processing of the information entered into the newsletter
        subscription form shall occur exclusively on the basis of your consent
        (Art. 6(1)(a) GDPR). You may revoke the consent you have given to the
        archiving of data, the e-mail address, and the use of this information
        for the sending of the newsletter at any time, for instance by clicking
        on the “Unsubscribe” link in the newsletter. This shall be without
        prejudice to the lawfulness of any data processing transactions that
        have taken place to date.
      </p>
      <p>
        The data deposited with us for the purpose of subscribing to the
        newsletter will be stored by us until you unsubscribe from the
        newsletter or the newsletter service provider and deleted from the
        newsletter distribution list after you unsubscribe from the newsletter
        or after the purpose has ceased to apply. We reserve the right to delete
        or block e-mail addresses from our newsletter distribution list at our
        own discretion within the scope of our legitimate interest in accordance
        with Art. 6(1)(f) GDPR.
      </p>
      <p>Data stored for other purposes with us remain unaffected.</p>
      <p>
        After you unsubscribe from the newsletter distribution list, your e-mail
        address may be stored by us or the newsletter service provider in a
        blacklist, if such action is necessary to prevent future mailings. The
        data from the blacklist is used only for this purpose and not merged
        with other data. This serves both your interest and our interest in
        complying with the legal requirements when sending newsletters
        (legitimate interest within the meaning of Art. 6(1)(f) GDPR). The
        storage in the blacklist is indefinite.{" "}
        <strong>
          You may object to the storage if your interests outweigh our
          legitimate interest.
        </strong>
      </p>
      <h2>6. Plug-ins and Tools</h2>
      <h3>YouTube</h3>
      <p>
        This website embeds videos of the website YouTube. The website operator
        is Google Ireland Limited (“Google”), Gordon House, Barrow Street,
        Dublin 4, Ireland.
      </p>
      <p>
        If you visit a page on this website into which a YouTube has been
        embedded, a connection with YouTube’s servers will be established. As a
        result, the YouTube server will be notified, which of our pages you have
        visited.
      </p>
      <p>
        Furthermore, YouTube will be able to place various cookies on your
        device or comparable technologies for recognition (e.g. device
        fingerprinting). In this way YouTube will be able to obtain information
        about this website’s visitors. Among other things, this information will
        be used to generate video statistics with the aim of improving the user
        friendliness of the site and to prevent attempts to commit fraud.
      </p>
      <p>
        If you are logged into your YouTube account while you visit our site,
        you enable YouTube to directly allocate your browsing patterns to your
        personal profile. You have the option to prevent this by logging out of
        your YouTube account.
      </p>
      <p>
        The use of YouTube is based on our interest in presenting our online
        content in an appealing manner. Pursuant to Art. 6(1)(f) GDPR, this is a
        legitimate interest. If appropriate consent has been obtained, the
        processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR
        and § 25 (1) TTDSG, insofar the consent includes the storage of cookies
        or the access to information in the user’s end device (e.g., device
        fingerprinting) within the meaning of the TTDSG. This consent can be
        revoked at any time.
      </p>
      <p>
        For more information on how YouTube handles user data, please consult
        the YouTube Data Privacy Policy under:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer">
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </p>
      <h3>Google Web Fonts</h3>
      <p>
        To ensure that fonts used on this website are uniform, this website uses
        so-called Web Fonts provided by Google. When you access a page on our
        website, your browser will load the required web fonts into your browser
        cache to correctly display text and fonts.
      </p>
      <p>
        To do this, the browser you use will have to establish a connection with
        Google’s servers. As a result, Google will learn that your IP address
        was used to access this website. The use of Google Web Fonts is based on
        Art. 6(1)(f) GDPR. The website operator has a legitimate interest in a
        uniform presentation of the font on the operator’s website. If
        appropriate consent has been obtained, the processing is carried out
        exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG,
        insofar the consent includes the storage of cookies or the access to
        information in the user’s end device (e.g., device fingerprinting)
        within the meaning of the TTDSG. This consent can be revoked at any
        time.
      </p>
      <p>
        If your browser should not support Web Fonts, a standard font installed
        on your computer will be used.
      </p>
      <p>
        For more information on Google Web Fonts, please follow this link:{" "}
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer">
          https://developers.google.com/fonts/faq
        </a>{" "}
        and consult Google’s Data Privacy Declaration under:
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer">
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </p>
      <h3>Font Awesome</h3>
      <p>
        This page uses Font Awesome for the uniform representation of fonts and
        symbols. Provider is Fonticons, Inc. 6 Porter Road Apartment 3R,
        Cambridge, Massachusetts, USA.
      </p>
      <p>
        When you call up a page, your browser loads the required fonts into its
        browser cache to display texts, fonts, and symbols correctly. For this
        purpose, the browser you use must connect to the servers of Font
        Awesome. This allows Font Awesome to know that your IP address has been
        used to access this website. The use of Font Awesome is based on Art.
        6(1)(f) GDPR. We have a legitimate interest in the uniform presentation
        of the typeface on our website. If appropriate consent has been
        obtained, the processing is carried out exclusively on the basis of Art.
        6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the
        storage of cookies or the access to information in the user’s end device
        (e.g., device fingerprinting) within the meaning of the TTDSG. This
        consent can be revoked at any time.
      </p>
      <p>
        If your browser does not support Font Awesome, a standard font from your
        computer will be used.
      </p>
      <p>
        Further information about Font Awesome can be found in the Font Awesome
        privacy policy at:{" "}
        <a
          href="https://fontawesome.com/privacy"
          target="_blank"
          rel="noopener noreferrer">
          https://fontawesome.com/privacy
        </a>
        .
      </p>
    </div>
  );
}

export default Datenschutz;

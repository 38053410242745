import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ReactComponent as Email } from "../assets/svg/115714_email_send_mail_icon.svg";

function Contactform() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5vnfswb",
        "template_5l9wblr",
        form.current,
        "rUlCi4TsyaxjWclej",
      )
      .then(
        (result) => {
          e.target.reset();
          alert("Nachricht wurde erfolgreich übertragen");
        },
        (error) => {
          alert("Die Übertragung hat leider nicht geklappt");
        },
      );
  };

  return (
    <div className="flex flex-col gap-4  p-4 md:flex-row">
      {/* Bereich für zusätzlichen Inhalt */}
      <div className="flex-center w-full max-w-lg flex-1 flex-col p-4 md:mx-auto">
        <div>
          <p className="text-center font-bold text-black  md:text-2xl xl:text-4xl">
            Ich freue mich über Ihre Kontaktaufnahme.<br></br> Dafür können Sie
            das Kontaktformular benutzen
          </p>
        </div>
        <div>
          <Email className="h-24 w-24 text-[#5D2555] xl:h-40 xl:w-40 xl:hover:animate-wiggle " />
        </div>
      </div>

      {/* Contactform-Bereich */}
      <div className="w-full max-w-lg flex-1 p-4 md:mx-auto ">
        <form ref={form} onSubmit={sendEmail} className="flex  flex-col gap-4">
          <div>
            <input
              type="text"
              name="user_name"
              required
              placeholder="Vor- und Nachname"
              className="mt-1 block w-full bg-[#FFF4E6] px-3 py-2 shadow-sm focus:outline-none xl:text-2xl"
            />
          </div>

          <div>
            <input
              type="email"
              name="user_email"
              className="mt-1 block w-full bg-[#FFF4E6] px-3 py-2 shadow-sm focus:outline-none xl:text-2xl"
              placeholder="Email"
              required
            />
          </div>

          <div>
            <textarea
              name="message"
              className="mt-1 block w-full bg-[#FFF4E6] px-3 py-2 shadow-sm focus:outline-none xl:text-2xl"
              required
              maxLength="500"
              placeholder="Nachricht"
            />
          </div>

          <input
            type="submit"
            value="Senden"
            className=" inline-flex justify-center bg-[#FFA07A] px-4 py-2 text-sm font-medium text-white shadow-sm transition-all hover:scale-105 hover:bg-[#FFA07A] focus:outline-none xl:text-2xl"
          />
        </form>
      </div>
    </div>
  );
}

export default Contactform;

import tailwindlogo from "./assets/svg/Tailwind_CSS_Logo.svg";
import pythonlogo from "./assets//png/python-logo-only.png";
import javascriptlogo from "./assets/png/Unofficial_JavaScript_logo_2.svg";
import reactlogo from "./assets/png/1174949_js_react js_logo_react_react native_icon.png";

const DATA = [

    {
        title: "React",
        icon: reactlogo,
        className: "bg-[#FFA07A]",
        beschreibung: "Für das Frontend meiner Projekte setze ich auf React, da es mir ermöglicht, schnelle und komponentenbasierte Layouts zu erstellen."
    },
    {
        title: "Tailwind",
        icon: tailwindlogo,
        className: "bg-[#FFF4E6]",
        beschreibung: "Das CSS-Styling meiner Projekte realisiere ich mit Tailwind, welches mir ein schnelles und individuelles Design ermöglicht."
    },
    {
        title: "Python",
        icon: pythonlogo,
        className: "bg-[#FFA07A]",
        beschreibung: "Python verwende ich sowohl für das Backend (mit Flask) als auch für verschiedene Projekte in den Bereichen Machine Learning, Business Intelligence und Automatisierung."
    },
    {
        title: "Javascript",
        icon: javascriptlogo,
        className: "bg-[#FFF4E6]",
        beschreibung: "JavaScript war meine erste Programmiersprache, die ich gelernt habe, und bildet die Grundlage für meine Arbeit mit React."
    }
]

export default DATA;
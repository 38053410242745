import React, { useState } from "react";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";

function Footer() {
  const [visibleComponent, setVisibleComponent] = useState("");

  const toggleComponent = (componentName) => {
    setVisibleComponent(
      visibleComponent === componentName ? "" : componentName,
    );

    // Rufe die Alert-Funktion auf, falls der Komponentenname "Cookies" ist
    if (componentName === "Cookies") {
      showCookieAlert();
    }
  };

  const showCookieAlert = () => {
    alert("Bitte drücken Sie auf den Fingerabdruckbutton (links)");
  };

  const handleClose = () => {
    setVisibleComponent("");
  };

  return (
    <div className="flex-center flex-col ">
      <div className="flex-center space-x-8 ">
        <div
          className="cursor-pointer font-bold"
          onClick={() => toggleComponent("Impressum")}>
          Impressum
        </div>
        <div
          className="cursor-pointer font-bold"
          onClick={() => toggleComponent("Datenschutz")}>
          Datenschutz
        </div>
        <div
          className="cursor-pointer font-bold"
          onClick={() => toggleComponent("Cookies")}>
          Cookies
        </div>
      </div>
      <div>
        {visibleComponent === "Impressum" && (
          <Impressum onClose={handleClose} />
        )}

        {visibleComponent === "Datenschutz" && (
          <Datenschutz onClose={handleClose} />
        )}
      </div>
    </div>
  );
}

export default Footer;

import React from "react";

function Faehigkeiten({ data }) {
  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-20 xl:px-32">
      {data.map((item, index) => (
        <div
          className="overflow-hidden bg-white shadow-lg transition duration-500 hover:-translate-y-1 hover:shadow-2xl"
          key={index}>
          <div className="border border-solid border-gray-100">
            <img
              className="h-24 w-full object-contain"
              src={item.icon}
              alt={item.title}
            />
          </div>
          <div
            className={`h-full p-4 text-center md:text-left ${item.className}`}>
            <h3 className="mb-2 font-semibold text-black md:text-2xl">
              {item.title}
            </h3>
            <p className="text-sm text-black">{item.beschreibung}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Faehigkeiten;

{
  /*
      <img src={data[0].Icon} className="h-32 w-32" />
      <img src={data[1].Icon} className="h-32 w-32" />
      <img src={data[2].Icon} className="h-32 w-32" />
      <img src={data[3].Icon} className="h-32 w-32" />



*/
  /*
   <div className="flex-center flex-col ">
      <div className="flex-center m-4 border border-gray-500">
        <h1 className="text-4xl font-extrabold">Meine Fähigkeiten</h1>
      </div>

      <div className="m-4 flex w-full justify-between ">
        {data.map((item, index) => (
          <div className="flex p-1 ">
            <div
              className={`flex-center flex w-full justify-between ${item.className} `}>
              <div className=" mr-2 text-2xl font-semibold">{item.title}</div>
              <div className="">
                <img src={item.Icon} className="h-32 w-32 " />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    */
}

{
  /*
function Faehigkeiten({ data }) {
  return (
    // Hinzufügen von seitlichem Padding bei großen Bildschirmen
    <div className="grid gap-4 md:grid-cols-2 md:gap-20 xl:px-32">
      {data.map((item, index) => (
        <div
          className="overflow-hidden bg-white shadow-lg hover:scale-105 "
          key={index}>
          <div className="border border-solid border-gray-100">
            <img
              className="h-24 w-full object-contain"
              src={item.icon}
              alt={item.title}
            />
          </div>
          <div className={`p-4 text-center md:text-left ${item.className}`}>
            <h3 className="mb-2 font-semibold text-black md:text-2xl">
              {item.title}
            </h3>
            <p className="text-sm text-black">{item.beschreibung}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
*/
}

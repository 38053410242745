import React from "react";

function Headersection({ text, id }) {
  return (
    <div>
      <div className="flex-center w-full ">
        <h1
          className="pb-4 pt-5 text-center  text-2xl text-[#FFA07A] md:text-5xl"
          id={id}>
          {text}
        </h1>
      </div>
    </div>
  );
}

export default Headersection;

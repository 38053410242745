import React from "react";

const PreLoader = ({ text }) => {
  const colors = ["#5D2555", "##FFA07A"];
  const animatedText = text.split("").map((char, index) => (
    <span
      key={index}
      style={{
        color: colors[index % colors.length],
        animationDelay: `${0.05 * index}s`,
        animationDuration: "0.9s",
      }}
      className="inline-block mx-1 text-4xl animate-bounce"
    >
      {char}
    </span>
  ));

  return (
    <div className="flex justify-center items-center h-screen">
      {animatedText}
    </div>
  );
};

export default PreLoader;

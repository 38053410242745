import React, { useState, useEffect } from "react";
import Navigation from "./Components/Navigation";
import Uebermich from "./Components/Uebermich";
import PreLoader from "./Components/PreLoader"; // Importiere die PreLoader-Komponente
import Faehigkeiten from "./Components/Faehigkeiten/Faehigkeiten";
import DATA from "./data";

import Headersection from "./Components/Faehigkeiten/Faehigkeitenheader";
import Contactform from "./Components/Contactform";
import Footer from "./Components/Footer/Footer";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    console.log("Herzlich Willkommen auf meiner Website!"); // Timer setzt loading nach 0.7 Sekunde auf false
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      {" "}
      {loading && <PreLoader text="Willkommen!" />}{" "}
      {/* PreLoader wird nur angezeigt, wenn loading true ist */}{" "}
      <div className={!loading ? "visible animate-fadeIn" : "invisible"}>
        {" "}
        {/* Tailwind CSS Klassen für Sichtbarkeit */}
        <Navigation />
        <Uebermich />
        <br></br>
        <br></br>
        <Headersection text="Technologien" id="faehigkeiten-header" />
        <br></br>
        <Faehigkeiten data={DATA} />
        <br></br>
        <br></br>
        <br></br>
        <Headersection text="Projekte" id="projekte" />
        <Headersection text="Aktuell werden die Projekte überarbeitet und stehen hier bald zur Verfügung" />
        <br></br>
        <br></br>
        <Headersection text="Kontakt" id="kontakt" />
        <Contactform />
        <br></br>
        <br></br>
        <br></br>
        <Footer />
        <br></br>
      </div>{" "}
    </div>
  );
}

export default App;

import React from "react";

function Impressum({ onClose }) {
  return (
    <div className="flex-center flex-col">
      <h1>
        <button className="text-2xl font-semibold " onClick={onClose}>
          Schließen
        </button>
      </h1>

      <h1>Impressum</h1>

      <h2>Angaben gemäß § 5 TMG</h2>
      <p>
        Isma?il Erdogan
        <br /> Bernad?dott?estr. 34
        <br /> 90?439? Nürn?berg
      </p>
      <p>
        {" "}
        Bitte entschuldigen Sie die Sonderzeichen in meinem Namen. Aber leider
        wird im Internet einfach mit meinem Angaben Verträge abgeschlossen.
        Durch die Sonderzeichen will das maschinelle Lesen verhindern
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: 01516?744?0571
        <br /> E-Mail: erdogan.isma @ gmail.com
      </p>

      <h2>Redaktionell verantwortlich</h2>
      <p>Ism??ail Erdo//gan</p>
      <h2>Bilder und Projekte</h2>

      <p>
        Die verwendeten Bilder sind alle mit Genehmigung oder von Pexels. Die
        verlinkten Projekte sind Projekte von Codecademy. Es ist erlaubt die
        Projekte und deren Inhalt zu teilen.
      </p>

      <h1>Site Notice</h1>

      <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
      <p>
        Isma?il Erdogan
        <br /> Bernad?dott?estr. 54
        <br /> 90?439? Nürn?berg
      </p>

      <h2>Contact</h2>
      <p>
        Phone: 0151??67??440?571
        <br /> E-mail: erdogan.isma @ gmail.com
      </p>

      <h2>Person responsible for editorial</h2>
      <p>Ismail Erdogan</p>
    </div>
  );
}

export default Impressum;

import React from "react";
import bild from "../assets/AvatarIsmail.png";

function Uebermich() {
  return (
    <div className="flex flex-col lg:flex-row-reverse" id="ueber-mich">
      <div className="  flex-1 lg:-mt-32 lg:ml-32">
        <div className=" flex-center h-full bg-gradient-to-br  from-[#5D2555] via-[#FFA07A] to-[#FFF4E6] ">
          <img className=" animate-float " src={bild} alt="avatar" />
        </div>
      </div>
      <div className="flex-center  flex-1 flex-col  bg-white">
        <div className=" pb-4 pt-5 text-center  text-2xl text-[#FFA07A] md:text-5xl ">
          Über mich
        </div>
        <div className=" pb-4  text-center md:text-2xl  ">Ismail Erdogan</div>
        <div className=" whitespace-pre-line pb-4 text-center md:text-lg   xl:text-xl ">
          Als IT-Consultant und Software-Entwickler in den späten Dreißigern
          bringe ich umfangreiche Erfahrungen in der Beratung sowie
          Softwareentwicklung mit.<br></br>Mein akademischer Hintergrund im
          Bereich Business Administration und meine Zertifizierungen im
          ISTQB-Foundation Level und Prince2 Foundation Level ermöglichen mir,
          technische Lösungen mit geschäftlichem Scharfsinn zu verbinden.
          <br></br>Meine Interessensgebiete umfassen Automatisierung, Künstliche
          Intelligenz und Business Intelligence, in denen ich innovative Ansätze
          und praktische Lösungen für Geschäftsprozesse verfolge.<br></br>Meine
          besondere Stärke liegt in der Kombination technischer und
          kaufmännischer Fähigkeiten, die es mir ermöglichen, Sachverhalte
          ganzheitlich zu betrachten und effektiv zu beraten.
        </div>
        <div className=" md:text-xl"></div>
        <div className=" md:text-xl"></div>
      </div>
    </div>
  );
}

export default Uebermich;

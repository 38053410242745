import { AiFillFire } from "react-icons/ai";
import { CgMenuGridO } from "react-icons/cg";
import React, { useEffect, useState } from "react";
import { ReactComponent as MeinIcon } from "../assets/logo.svg.svg";

function Navigation() {
  const werteFuerNavi = [
    { name: "Home", href: "/" },
    { name: "Über mich", href: "#ueber-mich" },
    { name: "Technologien", href: "#faehigkeiten-header" },
    { name: "Projekte", href: "#projekte" },
    { name: "Kontakt", href: "#kontakt" },
  ];
  const [isMobilMenuShown, setIsMobilMenuShown] = useState(false);

  return (
    <div>
      <nav className="relative z-10 flex flex-wrap items-center justify-between">
        <a href="#">
          <MeinIcon className="h-20 w-20" />
        </a>
        <button
          className="rounded-lg p-2 focus:ring-2 focus:ring-gray-200 lg:hidden"
          onClick={() => setIsMobilMenuShown(!isMobilMenuShown)}>
          <CgMenuGridO size={25} />
        </button>

        <div
          className={`${
            isMobilMenuShown ? "" : "hidden"
          } w-full lg:block lg:w-auto`}>
          <ul className="flex flex-col rounded-lg border border-gray-100 bg-gray-100 p-4 px-3 py-2 text-lg lg:flex-row lg:space-x-6 lg:border-none lg:bg-transparent">
            {werteFuerNavi.map((item, index) => (
              <li
                key={item.name}
                className={`cursor-pointer px-3 py-2 ${
                  index === 0
                    ? "rounded bg-[#FFA07A] text-white lg:bg-transparent lg:text-[#FFA07A]"
                    : "hover:bg-gray-200 lg:hover:bg-transparent lg:hover:text-blue-400"
                } ${
                  index === 3 || index === 4 ? "text-black lg:text-white" : ""
                }`}>
                <a href={item.href}>{item.name}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="fixed bottom-4 left-4 hidden lg:static lg:block">
          <div className="h-20 w-20"></div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
